<template>
  <div class="venue">
    <TopNavBar2 />
    <Carousel :carousels="carousels" />
    <div class="design_box">
      <Title title="设计理念" />
      <div class="design">
        <img src="@/assets/images/product/venue/bg1.png" alt="" />
        <div class="designs">
          <div v-for="(item, index) in designs" :key="index" class="item">
            <h3>
              <img
                :src="
                  require('@/assets/images/product/venue/icon' + index + '.png')
                "
                alt=""
              />
              {{ item.title }}
            </h3>
            <p>
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="characteristic_box">
      <Title title="系统特点" />
      <img src="@/assets/images/product/venue/bg2.png" alt="" />
    </div>
    <div class="box">
      <div class="framework_box">
        <Title title="架构图" />
        <div class="framework">
          <img src="@/assets/images/product/venue/bg3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="box">
      <div class="application_box">
        <Title title="场景及应用" />
        <div class="application">
          <div class="item left" style="right: -36px">藏品管理</div>
          <div class="item left" style="right: -66px">展品管理</div>
          <div class="item left" style="right: -78px">资源管理</div>
          <div class="item left" style="right: -66px">客流监测</div>
          <div class="item left" style="right: -36px">中控管理</div>
          <div class="item right" style="left: -35px">信息发布</div>
          <div class="item right" style="left: -66px">票务管理</div>
          <div class="item right" style="left: -78px">研学管理</div>
          <div class="item right" style="left: -66px">文创销售</div>
          <div class="item right" style="left: -36px">志愿者管理</div>
          <div class="item center">权限管理</div>
          <div class="item center">监测系统</div>
          <div class="middle">智慧政务</div>
        </div>
      </div>
    </div>
    <Footer2 />
  </div>
</template>

<script>
export default {
  name: "Venue",
  data() {
    return {
      designs: [
        {
          title: "决策可视化",
          content:
            "利用大数据可视化技术、GIS技术、3D建模技术、数字孪生技术、实景地图及地理标志实现决策应用和监测应用的深度还原和融合。",
        },
        {
          title: "应用公众化",
          content: "面向公众提供全矩阵化的公众数字化场馆参观应用。",
        },
        {
          title: "操作智能化",
          content:
            "采用AI智能语音交互控制技术，实现智能监测大屏以及各类常用系统和应用的智能语音交互控制模式。",
        },
        {
          title: "监测实时化",
          content:
            "视频流、传感器等各类监测设备数据以及场馆各类业务流程和节点数据实时展示和呈现。",
        },
        {
          title: "架构层次化",
          content:
            "保证物理层、软件层、数据层的优化设计和应用，同时在应用层实现五层应用架构。",
        },
        {
          title: "功能扩展化",
          content:
            "基于场馆智慧运维的场景及功能多元化扩展，适时对智能监测大屏可视化应用进行功能扩展和迭代，保证场馆整体智慧运维的同步提升。",
        },
      ],
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/9.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.venue {
  .box {
    display: flex;
    justify-content: center;
  }
  .design_box {
    margin-top: 20px;
    .design {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 15px;
      > img {
        width: 345px;
        height: 200px;
      }
      .designs {
        .item {
          h3 {
            font-family: "AlibabaPuHuiTi-Regular";
            font-weight: 400;
            font-size: 15px;
            height: 20px;
            color: #333333;
            display: flex;
            align-items: center;
            margin: 15px 0 5px;
            img {
              width: 15px;
              height: 15px;
              margin-right: 5px;
            }
          }
          p {
            font-family: "AlibabaPuHuiTi-Light";
            font-weight: 200;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
          }
        }
      }
    }
  }
  .characteristic_box {
    height: 193px;
    padding: 20px 0;
    margin-top: 40px;
    background-color: #f7fbffcc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    img {
      width: 345px;
      height: 148px;
    }
  }
  .framework_box {
    margin-top: 30px;
    .framework {
      width: 345px;
      height: 237px;
      margin-top: 20px;
      background-color: #ffffff;
      box-shadow: 0px 0px 30px #eeeeeeb3;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 325px;
        height: 217px;
      }
    }
  }
  .application_box {
    width: 100%;
    padding-top: 40px;
    height: 316px;
    background: linear-gradient(180deg, #ffffff 0%, #ddeaff 101%);
    .application {
      position: relative;
      margin: 40px auto 0;
      height: 201px;
      width: 201px;
      background-image: url(~@/assets/images/product/venue/bg5.png);
      background-repeat: no-repeat;
      background-size: cover;
      .item {
        position: absolute;
        width: 70px;
        font-family: "AlibabaPuHuiTi-Light";
        font-weight: 200;
        font-size: 14px;
        line-height: 18px;
        color: #333;
      }
      .center {
        text-align: center;
      }
      .left {
        text-align: left;
      }
      .right {
        text-align: right;
      }
      .item:nth-child(1),
      .item:nth-child(6) {
        top: 12px;
      }
      .item:nth-child(2),
      .item:nth-child(7) {
        top: 45px;
      }
      .item:nth-child(3),
      .item:nth-child(8) {
        top: 50%;
        transform: translateY(-50%);
      }
      .item:nth-child(4),
      .item:nth-child(9) {
        bottom: 45px;
      }
      .item:nth-child(5),
      .item:nth-child(10) {
        bottom: 12px;
      }
      .center:nth-child(11) {
        top: -22px;
        left: 50%;
        transform: translateX(-50%);
      }
      .center:nth-child(12) {
        bottom: -22px;
        left: 50%;
        transform: translateX(-50%);
      }
      .middle {
        position: absolute;
        width: 40px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 20px;
        font-family: "AlibabaPuHuiTi-Regular";
      }
    }
  }
}
</style>
